<template>
    <section class="is-relative min-vh-100">
        <div v-if="!section.attributes || loading" class="is-overlay is-flex is-absolute-center">
            <font-awesome-icon spin :icon="['fal', 'spinner-third']" size="3x"/>
        </div>
        <div v-else>
            <h3 class="title is-3">{{section.title}}</h3>
            <hr>
            <div class="columns mb-6">
                <div class="column is-6">
                    <text-input v-if="section.attributes && section.attributes.title"
                                class="box"
                                label="Title"
                                :value="section.attributes.title"
                                @input="updateTitle"
                                :key="titleKey" />
                    <text-input v-if="section.attributes && section.attributes.email"
                                class="box"
                                label="Email"
                                :value="section.attributes.email"
                                @input="updateEmail" :key="emailKey" />
                    <social-medias class="box"
                                   label="Social Media"
                                   :follow="follow"
                                   :key="follow.map(account => account['social-media']).join('|')"
                                   @socialSort="updateSocialSort"
                                   @deleteAccount="deleteSocialMedia"
                                   @updateAccount="updateSocial"
                                   @addAccount="addSocial"/>
                </div>
                <div class="column is-6">
                    <Wysiwyg v-if="section.attributes && section.attributes.bio"
                             class="box"
                             label="Bio"
                             :html="section.attributes.bio"
                             @updated="updateBio"
                             :key="bioKey" />
                </div>
            </div>
            <div class="buttons is-right">
                <button v-if="formIsDirty"
                        @click="cancelEdits"
                        class="button is-warning is-light">
                    Cancel
                </button>
                <button class="button is-link is-light"
                        :disabled="!formIsDirty" @click.prevent="saveChanges">
                    Save
                </button>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <h3 class="title is-3">Preview</h3>
                    <hr>
                    <main-content>
                        <about-me v-if="section.attributes" v-bind="section.attributes" />
                    </main-content>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {getSection, updateSection} from '@/utils/api';
    import MainContent from '../main-content';
    import AboutMe from '../about-me';
    import TextInput from '../form/TextInput';
    import Wysiwyg from '../form/Wysiwyg';
    import SocialMedias from '../form/SocialMedias';

    export default {
        components: {
            AboutMe,
            TextInput,
            Wysiwyg,
            MainContent,
            SocialMedias
        },
        data() {
            return {
                titleKey: Math.random().toString(36).substring(2, 15),
                emailKey: Math.random().toString(36).substring(2, 15),
                bioKey: Math.random().toString(36).substring(2, 15),
                original: {},
                section: {},
                follow: [],
                loading: true,
                formIsDirty: false
            }
        },
        mounted() {
            let { token } = window.netlifyIdentity.currentUser();

            getSection('about-me', token.access_token)
                .then(section => {
                    this.original = section;
                    this.section = JSON.parse(JSON.stringify(section));
                    this.follow = section.attributes.follow
                    this.loading = false;
                });
        },
        methods: {
            updateSocialSort: function (e) {
                let tempSection = JSON.parse(JSON.stringify(this.section));

                tempSection.attributes.follow = e;

                this.section = tempSection;
            },
            updateBio: function (html) {
                let tempSection = JSON.parse(JSON.stringify(this.section));

                tempSection.attributes.bio = html;

                this.section = tempSection;
            },
            deleteSocialMedia: function (account) {
                let tempSection = JSON.parse(JSON.stringify(this.section));
                let newFollow = tempSection.attributes.follow
                    .filter(smAccount => smAccount['social-media'] !== account.network);

                tempSection.attributes.follow = newFollow;

                this.section = tempSection;
                this.follow = JSON.parse(JSON.stringify(newFollow));
            },
            cancelEdits: function () {
                this.section = JSON.parse(JSON.stringify(this.original));
                this.follow = JSON.parse(JSON.stringify(this.original.attributes.follow));

                //Force re-render of text edits
                this.titleKey = Math.random().toString(36).substring(2, 15);
                this.emailKey = Math.random().toString(36).substring(2, 15);
                this.bioKey = Math.random().toString(36).substring(2, 15);
            },
            updateSocial: function (account) {
                let tempSection = JSON.parse(JSON.stringify(this.section));
                let newFollow = tempSection.attributes.follow
                    .map(smAccount => {
                        if (smAccount['social-media'] === account.replaces) {
                            return {
                                'social-media': account.replacement.network,
                                destination: account.replacement.destination
                            };
                        }

                        return smAccount;
                    });

                tempSection.attributes.follow = newFollow;

                this.section = tempSection;
                this.follow = JSON.parse(JSON.stringify(newFollow));
            },
            addSocial: function (e) {
                let tempSection = JSON.parse(JSON.stringify(this.section));
                tempSection.attributes.follow.push(e);

                this.section = tempSection;
                this.follow = JSON.parse(JSON.stringify(tempSection.attributes.follow));
            },
            updateTitle: function (e) {
                let { val } = e;
                let tempSection = JSON.parse(JSON.stringify(this.section));
                tempSection.attributes.title = val;

                this.section = tempSection;
            },
            updateEmail: function (e) {
                let { val } = e;
                let tempSection = JSON.parse(JSON.stringify(this.section));
                tempSection.attributes.email = val;

                this.section = tempSection;
            },
            saveChanges: function () {
                this.loading = true;

                let { token = {} } = window.netlifyIdentity.currentUser();
                let { access_token } = token;

                updateSection({
                    token: access_token,
                    component: 'about-me',
                    attributes: JSON.parse(JSON.stringify(this.section.attributes))
                })
                .then(res => {
                    this.original = res.data.sections.find(section => section.component === 'about-me');
                    this.section = JSON.parse(JSON.stringify(this.original));
                    this.$emit('sectionUpdated', res.data);
                })
                .catch(err => alert(err.message))
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        watch: {
            section: function () {
                this.formIsDirty = JSON.stringify(this.section) !== JSON.stringify(this.original);
            }
        }
    }
</script>

<style scoped>

</style>