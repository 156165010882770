import { render, staticRenderFns } from "./SocialMedias.vue?vue&type=template&id=69ffcb26&scoped=true&"
import script from "./SocialMedias.vue?vue&type=script&lang=js&"
export * from "./SocialMedias.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69ffcb26",
  null
  
)

export default component.exports