<template>
    <div class="field">
        <label for="social-medias-panel" class="label is-flex align-baseline justify-between">
            <span>{{label}}</span>
            <button @click="addAccount" class="button is-success is-light is-small">
                <span class="icon"><font-awesome-icon :icon="['fal', 'plus-circle']" size="lg"/></span>
                <span>add</span>
            </button>
        </label>
        <div id="social-medias-panel">
            <draggable class="menu-list"
                       v-model="followList"
                       @end="emmitSort"
                       handle=".grip"
                       ghost-class="gripping"
                       direction="horizontal"
                       :swapThreshold="0.5">
                <social-media v-for="(account, i) in followList"
                              :key="`social-media-admin-${i}-${account['social-media']}`"
                              @deleteAccount="deleteAccount"
                              @updateAccount="updateAccount"
                              :social-media="account" />
            </draggable>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import SocialMedia from './SocialMedia';

    export default {
        components: {
            SocialMedia,
            Draggable
        },
        props: {
            follow: Array,
            label: String
        },
        data() {
            return {
                followList: []
            }
        },
        mounted() {
            this.followList = JSON.parse(JSON.stringify(this.follow));
        },
        methods: {
            emmitSort: function () {
                this.$emit('socialSort', JSON.parse(JSON.stringify(this.followList)))
            },
            deleteAccount: function (e) {
                //this.followList = this.followList.filter(account => account['social-media'] !== e.network);
                this.$emit('deleteAccount', e);
            },
            addAccount: function () {
                let unusedAccounts = [
                    'instagram',
                    'linkedin',
                    'facebook',
                    'twitter'
                ].filter(account => !this.followList.map(smAccount => smAccount['social-media']).includes(account));

                let newAccount = {
                    'social-media': unusedAccounts[0],
                    destination: ''
                }
                this.followList.push(newAccount);
                this.$emit('addAccount', newAccount);
            },
            updateAccount: function (e) {
                this.followList = this.followList.map(account => {
                    if (account['social-media'] === e.replaces) {
                        return {
                            'social-media': e.replacement.network,
                            'destination': e.replacement.destination
                        };
                    }

                    return account;
                })
                this.$emit('updateAccount', e);
            }
        },
    }
</script>

<style scoped>

</style>