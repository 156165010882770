<template>
    <div class="columns is-vcentered">
        <div class="column is-narrow">
            <div class="has-text-centered">
                <font-awesome-icon class="grip has-text-right" :icon="['far', 'grip-vertical']"/>
            </div>
        </div>
        <div class="column pl-0">
            <div class="field has-addons">
                <p class="control has-icons-left">
            <span class="select">
              <select v-model="network" aria-label="Social Media Network">
                <option value="instagram">Instagram</option>
                <option value="linkedin">LinkedIn</option>
                <option value="facebook">Facebook</option>
                <option value="twitter">Twitter</option>
              </select>
            </span>
                    <span v-if="network" class="icon is-small is-left">
                <font-awesome-icon :icon="['fab', network]"/>
            </span>
                </p>
                <p class="control is-expanded">
                    <input v-model="destination"
                           class="input"
                           type="text"
                           aria-label="Link"
                           placeholder="Link to Social Media Account">
                </p>
                <p class="control">
                    <button @click="deleteAccount" class="button is-danger is-outlined">
                        <font-awesome-icon :icon="['fal', 'trash']" />
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            socialMedia: Object
        },
        data() {
            return {
                network: null,
                destination: null
            }
        },
        mounted() {
            this.network = this.socialMedia['social-media'];
            this.destination = this.socialMedia.destination;
        },
        methods: {
            deleteAccount: function () {
                let { network, destination } = this;
                this.$emit('deleteAccount', {network, destination});
            }
        },
        watch: {
            destination: function (newDest) {
                let { network } = this;
                let event = {
                    replaces: network,
                    replacement: {network, destination: newDest}
                };
                this.$emit('updateAccount', event);
            },
            network: function (newNetwork, oldNetwork) {
                let { destination } = this;
                let event = {
                    replaces: oldNetwork,
                    replacement: {network: newNetwork, destination}
                };
                this.$emit('updateAccount', event);
            }
        }
    }
</script>

<style scoped>

</style>